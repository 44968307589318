.max-widthE{
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
    max-width: unset;
}

.crousel-contentE{
position: relative;
    /* top: 40%;
    left: 60px; */
    z-index: 2;
    /* transform: translateY(-50%); */
    z-index: 9;
    max-width: 90%;
    text-align: left;
    filter: drop-shadow(0px 2px 7px rgba(5, 17, 33, 0.79));
}


.crousel-contentE h2{
font-style: normal;
color: #ffffff;
    font-weight: bold;
    font-size: 64px;
    line-height: 64px;
    letter-spacing: 1.1px;
    margin-bottom: 14px;
    position: relative;
}


.crousel-contentE p{
    color: #ffffff;

/* font-family: Roboto; */
    font-style: normal;
    font-weight: 300;
    font-size: 34px;
    line-height: 48px;
    letter-spacing: 0.1px;
    position: relative;
}
.card_event .MuiCardContent-root{
    min-height: 230px !important;
}


@media (max-width:500px) {

    .crousel-contentE h2 {
        font-style: normal;
        color: #ffffff;
        font-weight: bold;
        font-size: 43px;
        padding-top: 7vh;
        letter-spacing: 1.1px;
        margin-bottom: 14px;
        position: relative;
    }
    .crousel-contentE p {
        color: #ffffff;
        /* font-family: Roboto; */
        font-style: normal;
        font-weight: 300;
        font-size: 21px;
        line-height: 35px;
        letter-spacing: 0.1px;
        position: relative;
    }
}