.dash_sub{
  background-color: var(--pinkColor) !important;
  height: 150px;
  margin: 20px;
  border-radius: 20px !important;
  color: white !important;
  text-align: center;
  font-size: 24px;
  width: 210px;
  font-weight: 600;
  padding-top: 50px;
  cursor: pointer;
  }
  .appbarMain{
    top: 70px !important;
        background-color: #151616 !important;
    position: sticky!important;  }
.D6_TS_GDiv_ImgS_Admin{
  width: 100%;
    height: 100%;
    border-radius: 50%;
    border-color: #52B9D1;
    border-style: solid;
}
.mainContent{
  padding: 0.5rem 2rem !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}
.img_grid{
  padding-left: 20px;
}
.form_switch{
  place-items: end;
}
.btn_profile{
  background: var(--redColor) !important;
  border-radius: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-left: 60px !important;
}
.btn_profile1{
  background: var(--blackColor) !important;
  border-radius: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-left: 30px !important;
}
.text_profile{
  font-size: 17px;

}
  @media (max-width: 1200px) {
    .D6_FE_DD {
        height: 150px ;
    }
}
.grid_contPPr{
  text-align: -webkit-center;
  padding-top: 20vh;
  height: 100%;
  align-content: center;
}
.MuiDataGrid-cellContent{
overflow: scroll !important;
text-overflow: clip !important;
justify-content: center  !important;
}
.MuiDataGrid-cell--textLeft{
  justify-content: center !important;
}
.MuiDataGrid-columnHeaderTitleContainer {
justify-content: center !important;}




.profile_head{
  color: var(--pinkColor) !important;
  padding-top: 4vh;
  text-align: start;
  font-weight: 600 !important;
  font-size: 20px !important;
}
.profile_typo{
  text-align: justify;
    font-size: 19px !important;
    padding-bottom: 10px !important;

}
.chip_orderPay{
  background-color: var(--pinkColor) !important ;
  color: white !important;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
}

  @media (max-width: 410px) {
    .grid_contPPr{
      padding-top: 5vh;
    }
    .appbarMain{
        top:0px !important
    }
    .img_grid{
      padding-left: 0px;
    }
    .gird_chip{
      text-align: -webkit-center;
      padding-top: 10px;
    }
    .dash_sub{
            height: 150px;
        margin: 10px;
     
        width: auto !important;
        padding-top: 65px;
      }
      .form_switch{
        place-items: start;
      }
    }