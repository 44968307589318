.ul_navigation{
    list-style: none;
    display: flex;
    justify-content: flex-start;
       align-items: center;
    margin: 24px 0 32px;
    padding: 0px;
}

.ul_navigation li{
    margin-right: 12px;
    cursor: pointer;
        text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}
.ul_navigation span{
    margin-right: 12px;
      }
      .btn_explore{
        margin-right: 45px !important;
    border: 1px solid var(--pinkColor) !important;
    color: var(--pinkColor) !important;
    border-radius: 10px !important;
    padding: 10px !important;
      }
      .icon_explore{
        padding-left: 5px;
        font-size: 20px;
      }
      .exploreP_DT_head {
        font-size: 24px !important;
        padding-top: 5vh;
        font-weight: 500 !important;
        padding-right: 35px !important;
        padding-bottom: 15px;
        cursor: pointer;
        padding-left: 10px;

    }
    .exploreP_DT_headA {
        font-size: 24px !important;
        padding-top: 5vh;
        font-weight: 500 !important;
        padding-right: 35px !important;
        padding-bottom: 15px;
        padding-left: 10px;

    
        border-bottom: 2px solid var(--pinkColor);
        cursor: pointer;
    }
    .offer_strip{
      padding: 0;
    position: absolute;
    transform: rotate(-90deg);
    text-align: center;
    margin-top: 48px;
    /* left: 44px; */
    margin-left: -47px;
    background: var(--pinkColor);
    height: 50px;
    width: 147px;
    line-height: 34px;
    }
    .offer_strip span{
      padding: 0;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
    }
  .offer_head{
    font-size: 19px !important;
    text-align: justify;
    font-weight: 600 !important;
  }
    .offer_date{
      font-size: 16px !important;
      text-align: justify;
      font-weight: 400 !important;
  }
  .offer_text{
    font-size: 18px !important;
    text-align: justify;
    font-weight: 400 !important;
    padding-top: 2vh;
  }
  .btn_offers{
    color: var(--pinkColor) !important;
    border: 1px solid var(--pinkColor) !important;
    margin-bottom: 5px !important;
  }
  .ppr_offer{
    margin-top: 4vh !important;
min-height: 148px !important;
  }
  .icon_review{
    padding-right: 20px;
    font-size: 21px;
    color: var(--pinkColor);
    cursor: pointer;
  }
 
  .review_text{
    font-size: 21px !important;
    text-align: center;
    font-weight: 400 !important;
    padding-top: 3vh;
  }
  .btn_review{
    background-color: var(--pinkColor) !important;
    margin-top: 2vh !important;
  }
  .chip_p{
    background-color: var(--pinkColor) !important;
    color: white !important;
    padding: 10px;
  }




  

.explore_crousel{
  height: 100% !important;
}
.explore_crousel .slick-next {
  right: 0px !important;
}
.explore_crousel .slick-slide video{
  /* width: 100% !important; */
  /* height: 630px !important; */
  /* height:100vh !important;
  width: 100% !important; */




  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: -1;
  transition: none;
  pointer-events: none;
}



.explore_crousel .slick-slide .img_bg{
  width: 100%;
  /* margin-top: 5vh; */
  background: url('../../images/EV_1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  max-width:100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  text-align: center;
  -webkit-filter: blur(15px) brightness(.6);
  filter: blur(15px) brightness(.6);
  z-index: 0;

}

.explore_crousel .slick-slide .blog_bgC{
 
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

}

.explore_crousel .slick-slide .max-width{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  max-width: unset;
}






  
.explore_crousel .slick-next:before {
  content: '' !important;
}
.explore_crousel .slick-dots {
  bottom: -10px !important;
}
.explore_crousel .slick-dots li{
  width:50px;
}
.explore_crousel .slick-dots li button:before {
  width: 50px !important;
  height: 5px !important;
  color: #ffffff !important;
  background: #e1163c !important;
  content: '' !important;
  border-radius: 5px;
  margin-top: 20px;
}
.explore_crousel .prevArrow {
  position: absolute;
  top: 50%;
  left: 10px;
  z-index: 100;
  cursor: pointer;
  font-size: 42px;
  color:white;
}

.explore_crousel .nextArrow {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 100;
  cursor: pointer;
  font-size: 42px;
  color:white;
}
.explore_crousel_div {
  text-align: -webkit-center !important;
}