.field_evd{
    padding-top: 20vh !important;
    width: 90%;
   

}
.btn_evd{
    margin-top: 20vh !important;
    background-color: var(--pinkColor) !important;
    padding: 10px !important;
    

}
.btn_evd2{
    background-color: var(--pinkColor) !important;
    border-radius: 20px !important;    
    padding: 5px 22px !important;    color: white !important;

}
.btn_evd3{
  background-color: red !important;
  border-radius: 20px !important;    
  padding: 5px 22px !important;   
   color: white !important;

}
.txt_evd{
    font-size: 26px !important;
    font-weight: 600 !important;
    /* padding-top: 7vh !important; */
    text-align: center !important;
    color: var(--pinkColor) !important;
}

.MuiDataGrid-cellContent{
    overflow: scroll !important;
    text-overflow: clip !important;
    justify-content: center  !important;
    }
    .MuiDataGrid-cell--textLeft{
      justify-content: center !important;
    }
    .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center !important;}



.chip_evd{
    background-color: var(--pinkColor) !important;
    padding: 10px !important;
    cursor: pointer !important;
    color: white !important;
    text-align: start;
}    
.chip_evd2{
    background-color:black !important;
    padding: 10px !important;
    cursor: pointer !important;
    color: white !important;
    text-align: start;
    margin-left: 20px !important;
} 
.value_evd{
    font-size: 19px !important;
    padding: 10px 20px;
  
}
.text1_evd{
    font-size: 19px !important;
    font-weight: 600 !important;
    text-align: justify;
    padding: 10px 20px;

}

.txt_field_evd{
  width: 70%;
}


.filter_values{
    width: 200px;
    /* height: 100px; */
    position: relative;
    border: 1px solid var(--bcolor);
    margin-top: 8px;
    border-radius: 10px;
    background: #ffff;
    border: 1px solid var(--pinkColor);

  }
  .filter_values ul{
padding: 10px;
margin: 0px;

    max-height: 200px;
    overflow: scroll;
  }
  .list_filter{
    list-style: none;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--pinkColor);
    cursor: pointer;

    border-bottom: 1px solid var(--pinkColor);
  }
  .list_filter:hover{
    background:#eee ;
  }
  
  .chip_drop{
    background: white !important;
    color: var(--pinkColor) !important;
    font-size: 18px !important;
    padding: 15px 10px !important;
    border: 1px solid var(--pinkColor) !important;
  }
  .btn_no{
    background: green !important;
    color: white !important;
    margin: 5px !important;  }

.btn_yes{
  background: red !important;
  color: white !important;
  margin: 5px !important;
}
