.img_event{
    width: 100%;
    border-radius: 20px;
    z-index: 96;
    height: 68vh;
    object-fit: cover;
    margin-top: 100px;
    margin-bottom: 30px;
    position: relative;
}
.dialog_signin .MuiDialog-paper{
margin: 10px !important;
}
.img_bg{
    width: 100%;
    /* margin-top: 5vh; */
    background: url('../../images/EV_1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    max-width:100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    text-align: center;
    -webkit-filter: blur(15px) brightness(.6);
    filter: blur(15px) brightness(.6);
    z-index: 0;

}

.eventP_date{
    font-size: 20px !important;
    font-weight: 500;
}
.icon_eventP{
    font-weight: 500;
    font-size: 21px;
    padding-right: 30px;
    color: var(--pinkColor);
}
.eventP_head{
    padding-top: 2vh;
    font-size: 35px !important;
    font-weight: 700 !important;

}
.eventP_text{
    padding-top: 1vh;
    font-size: 20px !important;
    /* max-width: 70%; */
    line-height: 1.7 !important;
}
.eventP_auth{
    font-size: 19px !important;
    font-weight: 500 !important;
}
.btn_eventP{
    margin-left: 45px !important;
    border: 1px solid var(--pinkColor) !important;
    color: var(--pinkColor) !important;
}
.btn_eventP1{
    margin-left: 45px !important;
    background: var(--pinkColor) !important;
    color: white !important;
}
.eventP_DT_head{
    font-size: 24px !important;
    padding-top: 3vh;
    font-weight: 700 !important;   
}
.icon_cont_eventP{
    height: 40px;
    width: 40px;
    background: #eee;
    text-align: center;
    border-radius: 50%;
}
.icon_eventP_D{
    padding: 10px;
    color: var(--pinkColor);
}
.W_W_head{
    font-size: 19px !important;
    font-weight: 500 !important;
}
.W_W_text{
    font-size: 16px !important;
    color: slategrey;
    padding-top: 1vh;
    text-align: justify;
}
.W_W_text1{
    font-size: 17px !important;
    cursor: pointer;
    color: var(--pinkColor);
    padding-top: 1vh;
    text-align: justify;
}
.W_W_icon1{
    padding-left: 10px;
    font-size: 17px;
}
.eventP_Detail{
    
    font-size: 17px !important;
    font-weight: 500 !important;
    /* color: slategray; */
    line-height: 1.8 !important;
    margin-top: 2vh !important;
    padding-bottom: 3vh;
    /* overflow-x: scroll; */

}
.eventP_Detail img{
    max-width: 100%;
}
.chip_tags{
    background-color: var(--pinkColor) !important;
    color: white !important;
    padding: 10px !important;
    margin-right: 10px !important;
    font-weight: 500 !important;
    margin-top: 10px;


}
.icon_share{
    font-size: 22px;
    margin-right: 25px;
    color: var(--pinkColor) !important;
    cursor: pointer;
}
.ppr_sales{
    width: 85%;
    height: 197px;
    border-radius: 20px !important;
    margin: 10px;
}
.text_sale{
    font-weight: 700 !important;
    font-size: 24px !important;
    padding-top: 6vh;
}
.btn_sales{
    margin-top: 2vh !important;
    width: 87%;
    background: var(--pinkColor) !important;
}
.related_events{
    background-color: rgb(238, 238, 238,0.4);
    /* height: 700px; */
    width: 90%;
    margin-top: 2vh;
    /* overflow-y: scroll; */
    padding-bottom: 20px;

}
.related_events_head{
    padding-top: 2vh;
    font-size: 19px !important;
    font-weight: 500 !important;
}
.typo_eventHead1 {
    text-align: justify;
    padding-top: 10px;
    font-weight: 600 !important;
    font-size: 16px !important;
}
.cont_relatedE{
    height: 700px;
    width: 90%;
    margin-top: 2vh;
    overflow-y: scroll;
    padding-bottom: 20px; 
}