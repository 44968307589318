.D6_SL_Navbar_Items {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
       background:rgba(0,0,0,0.4);
   position: fixed; 
    top: 0;
    z-index: 101;
    border-bottom-style: solid;
    border-bottom-color: var(--pinkColor);
    border-bottom-width: 3px;

}
.D6_SL_Navbar_Link1{
    
    color:#ffff !important;

    text-decoration: none;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}
.D6_SL_Navbar_ItemsA {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
       background: black;
   position: fixed; 
    top: 0;
    z-index: 101;
    border-bottom-style: solid;
    border-bottom-color: var(--pinkColor);
    border-bottom-width: 2px;

}
.Grid_MC{
    height: 100%;
    align-content: center;
    justify-content:end;
    padding-right: 60px;
}
.D6_SL_Navbar_Items1 {
    width: 100%;
   
    display: flex;
    justify-content: space-between;
 
    color: #ffffff;

    z-index: 101;
}
.D6_SL_Navbar_Link2 {
    
    border-bottom-width: 2px;
    text-decoration: solid;
    color: var(--blueColor3);

    font-weight: 600;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom-style: solid;
    padding-bottom: 5px;

}
.D6_SL_Navbar_Link2N {
    
    border-bottom-width: 2px;
    text-decoration: solid;
    color: var(--blueColor3);
    font-weight: 600;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom-style: solid;
    padding-bottom: 5px;

}
.icon_gridM{text-align: start;
    padding-top: 5px;
    padding-bottom:5px;
padding-left: 20px;
cursor: pointer;}
.icons_nav{
    padding-left: 5px;
    padding-right: 5px;
    font-size: 21px !important;
}
.cart_count{
    position: absolute;
    padding: 0;
    font-size: 14px;
    transform: translate(-36%,-25%);
    min-width: 18px;
    text-align: center;
    height: 18px;
    border-radius: 50%;
    background-color: #c00;
    padding-top: 1px;
}
.icon_ham{
    color:white;
    font-size: 26px;
    padding-left: 20px;
    margin-top: 2px;
    /* font-weight: 500 !important; */
    align-self: center;
}
.icon_gridM1{
   display: none;
}
@media (max-width:500px) {


    .Grid_MC{
       display: none;
    }
    .icon_gridM1{
        display: block;

        align-self: center;
        text-align-last: end;
    }
    .D6_SL_Navbar_Items1{
        display: none;
    }
}