.home_topI{
    height: 60vh;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
}
.grid_home{
    width: 100%;
    text-align-last: center;
    position: absolute;
    top: 15%;
    text-align: -webkit-center;
}
.max-width{
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
    max-width: unset;
}

.crousel-content{
position: relative;
    /* top: 40%;
    left: 60px; */
    z-index: 2;
    /* transform: translateY(-50%); */
    z-index: 9;
    max-width: 90%;
    text-align: left;
    filter: drop-shadow(0px 2px 7px rgba(5, 17, 33, 0.79));
}


.crousel-content h2{
font-style: normal;
color: #ffffff;
    font-weight: bold;
    font-size: 64px;
    line-height: 64px;
    letter-spacing: 1.1px;
    margin-bottom: 14px;
    position: relative;
}


.crousel-content p{
    color: #ffffff;

/* font-family: Roboto; */
    font-style: normal;
    font-weight: 300;
    font-size: 34px;
    line-height: 48px;
    letter-spacing: 0.1px;
    position: relative;
}
.tf_home{
    background-color: rgba(255, 255, 255,0.5)  !important;
    color: white !important;
    text-align: justify;
}
.btn_search{
background-color: var(--pinkColor) !important;
}
.ppr_1{
    margin-top: 3vh;
    padding: 10px;
    border-left: 5px solid var(--pinkColor);
    background-color: #eee !important;
}
.ppr_1_head{
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: justify;
    padding-left: 20px;
    padding-bottom: 10px;
}
.ppr_1_text{
    text-align: justify;
    padding-left: 20px;
    font-size: 17px !important;
}
.typo_happ{
    text-align: justify;
    margin-top: 3vh !important;
    font-size: 24px !important;
    font-weight: 600 !important;
}
.typo_happ span{
    font-weight: 800;
    color: var(--pinkColor);
    font-size: 26px;
    border-bottom: 2px solid var(--pinkColor);
    margin-left: 15px;
    padding-left: 10px;

}
.typo_happ i {
    font-size: 26px !important;
    padding-left: 15px;
    color: var(--pinkColor);
}
.typo_cat{
    padding: 20px 20px 10px 20px;
    font-weight: 600 !important;
    cursor: pointer !important;
}
.typo_catA{
    padding: 20px 20px 10px 20px;
    font-weight: 600 !important;
    border-bottom: 1px solid var(--pinkColor);
    color: lightslategrey;
    cursor: pointer !important;

}
.trending_head{

        text-align: justify;
        margin-top: 3vh !important;
        font-size: 23px !important;
        font-weight: 600  !important;
        padding-bottom: 20px;
}
.card_category {
    position: absolute;
    margin-top: -44px;
    background: rgba(0,0,0,0.65);
    color: white;
    border-radius: 20px;
    padding-bottom: 0px !important;
    padding-top: 3px !important;
    margin-left: 10px;
}

.typo_card_category{
    padding-top: 3px;
    padding-bottom: 3px;

    font-size: 17px !important;
    padding-left: 5px;
    padding-right: 5px;
}
.grid_card{
    padding-top: 1vh;
    padding-bottom: 3vh;
    padding-inline: 5px !important;
    cursor: pointer;
    text-align: -webkit-center;
}
.img_card{
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    

}
.grid_card:hover .img_card{
    -webkit-transform: scale(1.5);
transform: scale(1.14);
}
.like_event{
    margin-top: -38px;
    margin-left: 224px;
    position: absolute;
    border-radius: 50%;
    background: var(--pinkColor);
}
.icon_event{
    font-size: 20px;
    padding: 11px;
    color: #ffffff;
}
.typo_eventHead{
    text-align: justify;
    padding-top: 10px;
    font-weight: 600 !important;
    font-size: 19px !important;
}
.typo_eventTime{
    padding-top: 15px;
    text-align: justify;
    font-size: 16px !important;
}
.typo_eventAuth{
    padding-top: 10px;
    text-align: justify;
    font-weight: 700 !important;
}
.typo_eventFollow{
    text-align: justify;
    padding-top: 10px;
}
.icon_user{
font-weight: 600 ;
color: var(--pinkColor);
}
.card_event:hover{
    box-shadow: 0 0 4px rgb(0 0 0 / 29%), 0 8px 16px rgb(0 0 0 / 30%);
}
.search_addr{
    max-width: 100%;
    min-height: 300px;
    background: white;
    border-radius: 10px;
    margin-top: 20px;
    border-bottom: 4px solid var(--pinkColor);
    overflow-y: scroll;
}

.input_places{
    width: 90%;
    height: 50px;
    /* background: rgba(0,0,0,0.1); */
    background-color: rgba(255, 255, 255,0.5) !important;
    border-color: transparent;
    border-radius: 5px;
}
.cont_search{
/* background-color: whitesmoke; */
text-align-last: left;
justify-content: start;
width: 100%  !important;
padding: 10px;

border-radius: 5
px;
cursor: pointer;
}
.main_txt{
font-size: 17px !important;
font-weight: 500 !important;
width: 100%;
text-align: left;
padding: 10px !important
}
.sub_txt{
    font-size: 15px !important;
    font-style: italic;
    width: 100%;
    text-align: left;
border-bottom: 1px solid var(--pinkColor);
/* padding-bottom: 5px; */
padding: 0px 10px 5px 10px !important


}
.grid_locations{
    padding-left: 40px;
}
.grid_search{
    padding-right: 50px;

}

@media (max-width:500px) {

    .grid_home {
       
        top: 10%;
    }
    .home_topI {
        height: 70vh;
        width: 100%;
        object-fit: cover;
        object-position: bottom;
    }
    .crousel-content h2 {
        font-style: normal;
        color: #ffffff;
        font-weight: bold;
        font-size: 43px;
        padding-top: 1vh;
        letter-spacing: 1.1px;
        margin-bottom: 14px;
        position: relative;
    }
    .crousel-content p {
        color: #ffffff;
        /* font-family: Roboto; */
        font-style: normal;
        font-weight: 300;
        font-size: 19px;
        line-height: 35px;
        letter-spacing: 0.1px;
        position: relative;
    }
    .grid_locations{
        padding: 10px !important;
    }
    .grid_search{
        padding: 10px !important;
    
    }
    .typo_happ {
        text-align: justify;
        margin-top: 3vh !important;
        font-size: 18px !important;
        font-weight: 600 !important;
    }
    .card_category {
        position: absolute;
        margin-top: -44px;
        background: rgba(0,0,0,0.65);
        color: white;
        border-radius: 20px;
        padding-bottom: 0px !important;
        padding-top: 3px !important;
        margin-left: 5px;
    }
    .typo_card_category {
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: 11px !important;
        padding-left: 5px;
        padding-right: 5px;
    }
   
    
}